<template>
    <div>
        <el-card class="management-report-card-box">
            <div>
                <el-form v-loading="$waiting.is('loading')">
                    <div class="flex flex-row justify-between items-center mb-2">
                        <h2 class="text-base font-semibold mb-2">{{ title }}</h2>
                        <div class="flex items-center">
                            <InfoButton :content="infoContent" />
                            <el-switch v-model="localToggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                        </div>
                    </div>
                    <div v-if="toggleValue" style="min-width: 800px; overflow-x: auto;">
                        <div v-if="changeInEquityData">
                            <div style="display: flex; margin-left: 310px; margin-bottom: 5px;">
                                <div v-for="(title, key) in titleData" :key="key" class="header-cell">
                                    <el-input
                                        class="break-normal custom-textarea"
                                        v-model="titleData[key]"
                                        type="textarea"
                                        :rows="2"
                                        style="width: 130px; margin-right: 20px;"
                                        :disabled="inputDisabled"
                                    />
                                </div>
                            </div>
                            <div style="width: 1350px;">
                                <el-table :data="calculatedTableDataRow && calculatedTableDataCol" size="mini" :show-header="false" style="width: 100%;">
                                    <el-table-column prop="changesInEquityDescription" width="300" editable>
                                        <template slot-scope="scope">
                                            <el-input v-model.number="scope.row.changesInEquityDescription" :disabled="inputDisabled" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="changesInEquityOne" width="150" editable>
                                        <template slot-scope="scope">
                                            <el-input v-model.number="scope.row.changesInEquityOne" :disabled="inputDisabled" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="changesInEquityTwo" width="150" editable>
                                        <template slot-scope="scope">
                                            <el-input v-model.number="scope.row.changesInEquityTwo" :disabled="inputDisabled" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="changesInEquityThree" width="150" editable>
                                        <template slot-scope="scope">
                                            <el-input v-model.number="scope.row.changesInEquityThree" :disabled="inputDisabled" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="changesInEquityFour" width="150" editable>
                                        <template slot-scope="scope">
                                            <el-input v-model.number="scope.row.changesInEquityFour" :disabled="inputDisabled" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="changesInEquityFive" width="150" editable>
                                        <template slot-scope="scope">
                                            <el-input v-model.number="scope.row.changesInEquityFive" :disabled="inputDisabled" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="changesInEquitySix" width="150" editable>
                                        <template slot-scope="scope">
                                            <el-input v-model.number="scope.row.changesInEquitySix" :disabled="inputDisabled" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="changesInEquitySeven" width="150" editable>
                                        <template slot-scope="scope">
                                            <el-input v-model.number="scope.row.changesInEquitySeven" disabled />
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div v-show="showComment">
                            <h2 class="text-base font-semibold mb-2 mt-4">Kommentar:</h2>
                            <el-input type="textarea" v-model="localInputText" :disabled="inputDisabled" class="break-normal custom-textarea" />
                        </div>
                    </div>
                </el-form>
                <CardEditButtons
                    :toggle-value="toggleValue"
                    :show-edit-button="showEditButton"
                    :toggle-edit="toggleEdit"
                    :close-modal="closeModal"
                    :create="create"
                    :show-comment-button="showCommentButton"
                    :show-comment="showComment"
                    @toggle-comment="handleToggleComment"
                />
            </div>
        </el-card>
    </div>
</template>
<script>
import { Switch } from "element-ui";
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        changeInEquityData: {
            type: Array,
            default: () => [],
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
        titleData: {
            type: Object,
            default: () => {},
        },
        inputText: {
            type: String,
            default: "",
        },
        showComment: {
            type: Boolean,
        },
    },

    data() {
        return {
            updateChangeInEquityData: [],
            selectedYear: null,
            financialYearId: null,
            annualReportId: undefined,
            inputDisabled: true,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            localInputText: this.inputText,
            showCommentButton: true,
            infoContent: `ÅRL 6 kap 2 § - Aktiebolag och ekonomiska föreningar ska i förvaltningsberättelsen eller i egen räkning specificera förändringar i eget kapital jämfört med föregående års balansräkning. <br /><br /> K2 (BFNAR 2016:10) kapitel 5 avsnittet Förändringar i eget kapital`,
        };
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("./components/CardEditButtons.vue"),
        InfoButton: () => import("./components/InfoButton.vue"),
    },
    watch: {
        inputText(newVal) {
            this.localInputText = newVal;
        },
        localInputText(newVal) {
            this.$emit("update:text", newVal);
        },
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
        showComment(newVal) {
            this.$emit("update:showChangesInEquityComment", newVal);
        },
    },
    computed: {
        calculatedTableDataRow() {
            return this.changeInEquityData.map(row => {
                const sum =
                    parseFloat(row.changesInEquityOne || 0) +
                    parseFloat(row.changesInEquityTwo || 0) +
                    parseFloat(row.changesInEquityThree || 0) +
                    parseFloat(row.changesInEquityFour || 0) +
                    parseFloat(row.changesInEquityFive || 0) +
                    parseFloat(row.changesInEquitySix || 0);
                this.$set(row, "changesInEquitySeven", sum);
                return row;
            });
        },
        calculatedTableDataCol() {
            const columnSums = {};

            this.changeInEquityData.forEach(row => {
                Object.keys(row).forEach((key, index) => {
                    if (index !== 0 && row.changesInEquityDescription !== "Belopp vid årets utgång") {
                        if (!columnSums[key]) {
                            columnSums[key] = 0;
                        }
                        columnSums[key] += parseFloat(row[key] || 0);
                    }
                });
            });
            Object.keys(this.changeInEquityData[this.changeInEquityData.length - 1]).forEach((key, index) => {
                if (index !== 0 && key !== "changesInEquityDescription") {
                    this.$set(this.changeInEquityData[this.changeInEquityData.length - 1], key, columnSums[key] || 0);
                }
            });

            return this.changeInEquityData;
        },
    },

    methods: {
        handleToggleComment(newShowComment) {
            this.showComment = newShowComment;
        },
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 6,
                value: newVal,
            };
            this.$emit("toggle-change", eventData);
        },
        async create() {
            this.updateChangeInEquityData = this.changeInEquityData;
            this.$emit("update-change-in-equity-data", this.updateChangeInEquityData);
            this.$emit("create");
            this.$notify.success({ title: 'Sparat "Förändringar i eget kapital"' });
            this.toggleEdit();
        },
        closeModal() {
            this.$emit("close");
            this.toggleEdit();
        },
    },
};
</script>
